<template>
	<MhRouterView class="Ov404View view background2--color-primary-background" :isContentReady="isContentReady">

		<div class="minHeight minHeight--100 vSpace vSpace--viewContent">

			<Ov404></Ov404>

		</div>
		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="page">{{page}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import Ov404 from '@/components/Ov404.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'

	export default {
		name: 'Ov404View',
		components: {
			MhRouterView,
			Ov404,
			OvMainFooter,
		},
		mixins: [],
		props: {},
		data() {
			return {
				isContentReady : true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted(){}
	}
</script>

<style lang="less" scoped>
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.Ov404View {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
